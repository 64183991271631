<template>
  <div>
    <Chart
      elementId="monthlyStatisticsChart"
      type="line"
      :data="data"
      :options="options"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { getListDaysInMonth, formatDate } from '@/utils/utils'
import Chart from '@/components/Chart.vue'

export default defineComponent({
  props: {
    monthlyLogs: {
      type: Array,
      required: true,
    },
    title: String,
  },
  components: {
    Chart,
  },
  setup(props) {
    const labels = getListDaysInMonth().map((day) => formatDate(day, 'DD-MM'))
    const data = {
      labels,
      datasets: [
        {
          label: 'Encrypt',
          backgroundColor: '#4F4789',
          data: Array(labels.length).fill(0),
        },
        {
          label: 'Decrypt',
          backgroundColor: '#119DA4',
          data: Array(labels.length).fill(0),
        },
      ],
    }
    const options = {
      plugins: {
        title: {
          display: true,
          text: props.title,
          font: {
            size: 18,
          },
        },
      },
    }
    const getDataFromLogSnap = (logSnap: any) => {
      let createdAt: number
      let type: string
      if (logSnap.val) {
        const value = logSnap.val()
        createdAt = value.createdAt
        type = value.type
      } else {
        createdAt = logSnap.createdAt
        type = logSnap.type
      }
      return { createdAt, type }
    }
    props.monthlyLogs.forEach((logSnap: any) => {
      const { createdAt, type } = getDataFromLogSnap(logSnap)
      const indexNumber = parseInt(formatDate(createdAt, 'D')) - 1
      const typeNumberInDatasets = type === 'encrypt' ? 0 : 1
      data.datasets[typeNumberInDatasets].data[indexNumber] += 1
    })
    return {
      data,
      options,
    }
  },
})
</script>
