<template>
  <div>
    <canvas :id="elementId"></canvas>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, toRefs } from 'vue'
import { Chart, registerables } from 'chart.js'
Chart.register(...registerables)

export default defineComponent({
  props: {
    elementId: {
      type: String,
      required: true,
    },
    data: Object,
    type: String,
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { elementId, type, data, options } = toRefs(props)
    const config = {
      type: type.value,
      data: data.value,
      options: options.value,
    } as any
    onMounted(() => {
      const chartEl = document.getElementById(
        elementId.value
      ) as HTMLCanvasElement
      new Chart(chartEl, config)
    })
  },
})
</script>
