
import { defineComponent } from 'vue'
import Chart from '@/components/Chart.vue'

export default defineComponent({
  components: {
    Chart,
  },
  props: {
    cryptoTypeStats: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const getCryptoTypeStatsProperty = (property: string) =>
      props.cryptoTypeStats.map((stats: any) => stats[property])
    const labels = getCryptoTypeStatsProperty('type')
    const data = {
      labels: labels,
      datasets: [
        {
          label: 'Encrypt',
          backgroundColor: '#4F4789',
          data: getCryptoTypeStatsProperty('encrypt'),
        },
        {
          label: 'Decrypt',
          backgroundColor: '#119DA4',
          data: getCryptoTypeStatsProperty('decrypt'),
        },
      ],
    }
    const options = {
      plugins: {
        title: {
          display: true,
          text: props.title,
          font: {
            size: 18,
          },
        },
      },
    }
    return {
      data,
      options,
    }
  },
})
